import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент заголовка содержимого лида.
 */
export const Heading = styled.h1`
  position: relative;
  z-index: 1;

  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 4.375rem;
  line-height: 4.4375rem;
  letter-spacing: -0.01em;

  @media ${lessThanContainer('medium')} {
    font-size: 4.3125rem;
    line-height: 4.375rem;
  }

  @media ${lessThanContainer('small')} {
    padding-bottom: 3.125rem;

    font-size: 2.25rem;
    line-height: 2.6875rem;
  }

  @media ${lessThanContainer('xSmall')} {
    padding-bottom: 1.125rem;

    font-size: 2rem;
    line-height: 2.6875rem;
  }
`;
