import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент описания содержимого лида.
 */
export const Description = styled.div`
  position: relative;
  z-index: 1;

  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.01em;

  opacity: 0.7;

  padding-top: 1.25rem;
  padding-bottom: 4.5625rem;

  @media ${lessThanContainer('small')} {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }
`;
