import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент описания.
 */
export const Description = styled.p`
  margin: 0 0 20px 0;

  font-style: normal;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: -1%;

  > span {
    display: block;
    margin-top: 1rem;
  }

  @media ${lessThanContainer('small')} {
    margin: 0 0 10px 0;

    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
`;
