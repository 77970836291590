import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Item.Root';
import { Name } from './Item.Name';
import { Image } from './Item.Image';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Имя пользователя
   */
  name: string;

  /**
   * Иконка социальной сети.
   */
  src: string;
};

/**
 * Отображает блок социальной сети.
 */
export const Item: FC<Props> = ({ href, name, src, title, ...props }) => (
  <Root href={href} target="_blank" rel="noopener noreferrer" {...props}>
    <Image src={src} alt={title} />
    <Name>{name}</Name>
  </Root>
);
