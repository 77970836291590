import styled from '@emotion/styled';

import { LinkButton as Link, lessThanContainer } from 'modules/common/ui';

/**
 * Ссылка элемента меню бокового меню подвала.
 */
export const LinkButton = styled(Link)`
  margin-top: 1.5rem;

  @media ${lessThanContainer('medium')} {
    font-size: 0.875rem;
    line-height: 1.225rem;

    padding: 0.625rem 2rem;
  }
`;
