import styled from '@emotion/styled';

/**
 * Корневой элемент блока.
 */
export const Root = styled.a`
  display: flex;
  align-items: center;
  gap: 1rem;

  text-decoration: none;

  color: inherit;
`;
