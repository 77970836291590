import styled from '@emotion/styled';

import { Container as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Отображает компонент контейнера.
 */
export const Container = styled(Base)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  padding-top: 12.5rem;
  padding-bottom: 12.5rem;

  @media ${lessThanContainer('large')} {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  @media ${lessThanContainer('medium')} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  @media ${lessThanContainer('small')} {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
`;
