import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает процент на карточке.
 */
export const Badge = styled.p`
  margin: 1.125rem 0 0;
  padding: 0.5rem 1.25rem;

  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;

  border-radius: 2rem;

  background-color: ${({ theme }) => theme.color.primary.contrast};

  @media ${lessThanContainer('small')} {
    margin-top: 0.625rem;

    font-size: 1rem;
    line-height: 1.4rem;
  }
`;
