import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Section as Base } from '../Section';

/**
 * Компонент отображающий секцию блока на главной странице.
 */
export const Section = styled(Base)`
  padding-bottom: 9.375rem;

  @media ${lessThanContainer('large')} {
    padding-bottom: 6.25rem;
  }

  @media ${lessThanContainer('small')} {
    padding-bottom: 3.125rem;
  }
`;
