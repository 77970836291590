import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import largeBackground from './assets/largeBackground.png';
import smallBackground from './assets/smallBackground.png';

/**
 * Корневой элемент лида главной страницы.
 */
export const Root = styled.div`
  background-image: url(${largeBackground});
  background-position: top center;
  background-color: black;
  background-size: cover;

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${lessThanContainer('small')} {
    background-image: url(${smallBackground});
    background-position: bottom center;
    background-size: contain;
  }
`;
