import styled from '@emotion/styled';

import { Container as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Отображает список карточек с плючами заема.
 */
export const Container = styled(Base)`
  display: grid;
  grid-template-columns: 37.5rem 25rem;
  grid-template-rows: repeat(2, auto);
  gap: 2.875rem;

  justify-content: center;

  @media ${lessThanContainer('large')} {
    grid-template-columns: 28.75rem 25rem;
  }

  @media ${lessThanContainer('medium')} {
    grid-template-columns: 1fr;

    gap: 1.875rem;
  }
`;
