import styled from '@emotion/styled';

import { LinkButton as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Кнопка блока.
 */
export const LinkButton = styled(Base)`
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;

  @media ${lessThanContainer('small')} {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;
