import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Обертка элемента ссылки содержимого лида.
 */
export const WrapLinkButton = styled.div`
  position: relative;
  z-index: 1;

  // margin-top: 3.75rem;

  @media ${lessThanContainer('small')} {
    margin-top: 1rem;

    // text-align: center;
  }
`;
