import { FC, ComponentPropsWithoutRef, ReactNode } from 'react';

import Head from 'next/head';

import { useRouter } from 'next/router';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Head>, 'children'> & {
  /**
   * Заголовок страницы.
   */
  title: string;

  /**
   * Описание страницы.
   */
  description?: string;

  /**
   * Ключевые слова страницы.
   */
  keywords?: string;

  /**
   * Изображение страницы.
   */
  image?: string;

  /**
   * Информация для роботов.
   */
  robots?: string;

  /**
   * Дочерние компоненты.
   */
  children?: ReactNode;
};

/**
 * Компонент метатегов, добавляет в секцию `<head>` страницы коллекцию
 * метатегов
 */
export const Meta: FC<Props> = ({
  children,
  title,
  description,
  keywords,
  image,
  robots,
  ...props
}) => {
  const isTitle = Boolean(title);
  const isKeywords = Boolean(keywords);
  const isDescription = Boolean(description);
  const isImage = Boolean(image);
  const isRobots = Boolean(robots);
  const site = process.env.NEXT_PUBLIC_HOST;
  const canonicalURL = site + useRouter().pathname;

  let finalRobots = robots;

  if (robots && process.env.NODE_ENV !== 'production') {
    finalRobots = 'noindex, nofollow';
  }

  return (
    <Head {...props}>
      {isTitle && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </>
      )}

      <link rel="canonical" href={canonicalURL} />

      {isKeywords && <meta name="keywords" content={keywords} />}

      {isDescription && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}

      <meta property="og:site_name" content="apollon-zaim" />
      <meta property="og:type" content="website" />

      {isImage ? (
        <meta property="og:image" content={image} />
      ) : (
        <meta property="og:image" content={`${site}/share.png`} />
      )}

      <meta property="og:url" content={canonicalURL} />
      <meta name="twitter:card" content="summary_large_image" />

      {isRobots && <meta name="robots" content={finalRobots} />}

      {children}
    </Head>
  );
};
