import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент заголовка.
 */
export const Title = styled.h4`
  grid-column: 1;

  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: -1%;

  @media ${lessThanContainer('medium')} {
    grid-row: 2;

    margin-top: 0.625rem;

    text-align: center;
  }

  @media ${lessThanContainer('small')} {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
`;
