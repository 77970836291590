import styled from '@emotion/styled';

import { Container as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Отображает компонент контейнера.
 */
export const Container = styled(Base)`
  padding-top: 7.1875rem;
  padding-bottom: 3.3125rem;

  @media ${lessThanContainer('small')} {
    padding-top: 5rem;
    padding-bottom: 1.125rem;
  }

  @media ${lessThanContainer('xSmall')} {
    padding-top: 4rem;
  }
`;
