import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Описание карточки.
 */
export const Description = styled.p`
  grid-row: 3;

  margin: 0;
  padding-top: 3.75rem;

  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.75rem;

  @media ${lessThanContainer('medium')} {
    grid-column: 1;
    grid-row: 2;
    align-self: start;

    padding-top: 0;
  }

  @media ${lessThanContainer('small')} {
    justify-self: center;
    grid-row: 3;

    font-size: 1rem;
    line-height: 1.5625rem;
    text-align: center;
  }
`;
