import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  grid-column: 2;
  grid-row: 1 / span 2;

  display: grid;
  grid-template-rows: repeat(3, auto);
  align-content: start;

  padding: 4.375rem 3.5rem 5rem 3.125rem;

  background-color: #f5f8fe;

  border-radius: 1.875rem;

  @media ${lessThanContainer('medium')} {
    grid-column: 1;
    grid-row: 3;

    grid-template-columns: 18.3rem auto;
    grid-template-rows: repeat(2, auto);
    gap: 2.875rem 0.875rem;
    align-items: center;

    padding-right: 2.6875rem;
  }

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
    gap: 1.25rem;

    padding: 1.875rem;
  }
`;
