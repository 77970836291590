import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка карточки.
 */
export const Image = styled.img`
  width: 23rem;
  height: auto;

  @media ${lessThanContainer('medium')} {
    width: 16.375rem;
  }

  @media ${lessThanContainer('medium')} {
    width: 12.3125rem;
  }
`;
