import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Описание карточки.
 */
export const Description = styled.p`
  grid-column: 1;
  align-self: start;

  margin: 0;
  padding-bottom: 3.125rem;

  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.9375rem;

  @media ${lessThanContainer('small')} {
    justify-self: center;

    padding-bottom: 0;

    font-size: 1rem;
    line-height: 1.5625rem;
    text-align: center;
  }
`;
