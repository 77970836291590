import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Landscape.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает пейзаж на главной.
 */
export const Landscape: FC<Props> = ({ children, ...props }) => (
  <Root {...props} />
);
