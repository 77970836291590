import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Main } from './Main';

import { Root } from './Lead.Root';
import { Content } from './Lead.Content';
import { Aside } from './Lead.Aside';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает лид главной страницы сайта.
 */
export const Lead: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>
    <Content>
      <Main />
      {children != null && <Aside>{children}</Aside>}
    </Content>
  </Root>
);
