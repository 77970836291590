import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка карточки.
 */
export const Image = styled.img`
  margin-top: 0.9375rem;

  width: 8.125rem;
  height: auto;

  @media ${lessThanContainer('small')} {
    margin-top: 0.625rem;

    width: 6.875rem;
  }
`;
