import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Social.Root';
import { Item } from './Item';

import twitter from './assets/twitter.svg';
import fb from './assets/fb.svg';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает блок "Социальные сети".
 */
export const Social: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Item
      href="https://twitter.com/"
      name="ivanovivan"
      title="twitter"
      src={twitter}
    />
    <Item
      href="https://ru-ru.facebook.com/"
      name="ivanov.ivan"
      title="facebook"
      src={fb}
    />
  </Root>
);
