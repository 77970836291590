import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Описание карточки.
 */
export const Description = styled.p`
  margin: 0;
  padding-top: 1.25rem;

  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.9375rem;
  text-align: center;

  @media ${lessThanContainer('small')} {
    padding-top: 0.75rem;

    font-size: 1rem;
    line-height: 1.55rem;
  }
`;
