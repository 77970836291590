import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  grid-column: 1;

  display: grid;
  grid-template-columns: 16rem auto;
  grid-template-rows: repeat(2, auto);
  gap: 2.875rem 0.875rem;
  align-items: center;

  padding-left: 3.5rem;

  background-color: #f5f8fe;

  border-radius: 1.875rem;

  overflow: hidden;

  @media ${lessThanContainer('large')} {
    gap: 2.875rem 0;
  }

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
    gap: 1.25rem;

    padding: 1.875rem;
  }
`;
