import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент отображающий слайд.
 */
export const Slide = styled.div`
  width: 100%;

  padding: 0 4.5rem 2.5rem;

  @media ${lessThanContainer('small')} {
    padding: 0 0 2.5rem;
  }
`;
