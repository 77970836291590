import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент описания.
 */
export const Description = styled.p`
  grid-column: 1;

  margin: 0.75rem 0 0 0;

  font-style: normal;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: -1%;

  @media ${lessThanContainer('medium')} {
    margin-top: 2.5rem;

    text-align: center;
  }

  @media ${lessThanContainer('small')} {
    margin-top: 1.875rem;

    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
`;
