import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Описание карточки.
 */
export const Description = styled.p`
  margin: 0.4375rem 0 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.color.secondary.darkest};

  @media ${lessThanContainer('small')} {
    margin-top: 1.25rem;

    font-size: 1rem;
    line-height: 1.4rem;
  }
`;
