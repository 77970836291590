import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 34.375rem;

  @media ${lessThanContainer('small')} {
    max-width: 17.5rem;
  }
`;
