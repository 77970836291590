import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Title as Base } from '../Title';

/**
 * Компонент заголовка.
 */
export const Title = styled(Base)`
  @media ${lessThanContainer('medium')} {
    text-align: center;
  }
`;
