import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Main.Root';
import { Heading } from './Main.Heading';
import { Text } from './Main.Text';
import { Accent } from './Main.Accent';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'children'>;

/**
 * Элемент основного содержимого лида.
 */
export const Main: FC<Props> = (props) => (
  <Root {...props}>
    <Heading>Всё по-честному</Heading>
    <Text>
      Онлайн заём под <Accent>0%</Accent> за <Accent>5 минут</Accent>
    </Text>
  </Root>
);
