import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';
import { transition } from 'modules/common/theme';

import { LinkButton } from './Card.LinkButton';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 23.75rem;

  padding: 3.125rem 2.375rem;

  background-color: #f5f8fe;

  border-radius: 1.875rem;

  position: absolute;
  transition: ${transition('height')};

  overflow: hidden;

  ${LinkButton} {
    position: relative;
    top: 3rem;

    transition: ${transition('top')};
  }

  :hover {
    height: 27.75rem;

    ${LinkButton} {
      top: 0;
    }
  }

  @media ${lessThanContainer('medium')} {
    position: relative;

    height: 100%;

    ${LinkButton} {
      top: 0;
    }

    :hover {
      height: 100%;
    }
  }

  @media ${lessThanContainer('small')} {
    padding: 1.875rem;
  }
`;
