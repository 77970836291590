import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заглавие карточки.
 */
export const Title = styled.div`
  grid-column: 1;
  align-self: end;

  margin: 0;
  padding-top: 3.125rem;

  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 2.875rem;

  @media ${lessThanContainer('small')} {
    grid-row: 2;

    justify-self: center;

    padding-top: 0;

    font-size: 1.375rem;
    line-height: 1.5625rem;
    text-align: center;
  }
`;
