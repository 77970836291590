import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка карточки.
 */
export const Image = styled.img`
  grid-row: 1;

  align-self: start;

  @media ${lessThanContainer('medium')} {
    grid-column: 2;
    grid-row: 1 / span 2;
    justify-self: end;
  }

  @media ${lessThanContainer('small')} {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;

    width: 4.5rem;
  }
`;
