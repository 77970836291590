import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка карточки.
 */
export const Image = styled.img`
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: end;

  padding-right: 1.875rem;

  @media ${lessThanContainer('large')} {
    align-self: start;

    width: 15rem;

    position: relative;
    top: 1rem;
  }

  @media ${lessThanContainer('small')} {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;

    padding-right: 0;

    top: auto;

    width: 6rem;
  }
`;
