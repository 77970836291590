import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент заголовка.
 */
export const Title = styled.h2`
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 3.4375rem;
  line-height: 5.125rem;
  letter-spacing: -1%;

  @media ${lessThanContainer('medium')} {
    font-size: 2.75rem;
    line-height: 3.5625rem;
  }

  @media ${lessThanContainer('small')} {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
`;
