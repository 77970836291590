import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Обертка кнопки блока.
 */
export const WrapLinkButton = styled.div`
  margin-top: 3.75rem;

  @media ${lessThanContainer('medium')} {
    text-align: center;
  }

  @media ${lessThanContainer('medium')} {
    margin-top: 2.5rem;
  }
`;
