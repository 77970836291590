import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент картинки.
 */
export const Image = styled.img`
  width: 25.75rem;
  height: 20.4375rem;

  @media ${lessThanContainer('large')} {
    position: absolute;
    right: -10rem;
  }

  @media ${lessThanContainer('medium')} {
    display: none;
  }
`;
