import styled from '@emotion/styled';

import { LinkButton as Link, lessThanContainer } from 'modules/common/ui';

/**
 * Элемент ссылка содержимого лида.
 */
export const LinkButton = styled(Link)`
  // padding: 0;

  // display: inline-block;

  // font-style: normal;
  // font-weight: 500;
  // font-size: 0.875rem;
  // line-height: 1.0625rem;
  // letter-spacing: -0.02em;

  // color: inherit;
  // opacity: 0.7;

  // border-bottom: 1px solid ${({ theme }) => theme.color.text.main.great};
  // border-radius: 0;

  // outline: none;

  // :hover {
  //   color: inherit;

  //   border-bottom: 1px solid ${({ theme }) => theme.color.text.main.great};
  // }

  // @media ${lessThanContainer('small')} {
  // }
  
  font-size: 3rem;
  border-color: white;
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;

  :hover {
    color: inherit;
    border-color: inherit;
    opacity: 60%;
  }

  @media ${lessThanContainer('small')} {
    font-size: 2rem;
  }
`;
