import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  grid-row: 2;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  gap: 1.75rem 1.625rem;
  align-items: center;

  padding: 4.625rem 3.5rem;

  background-color: #f5f8fe;

  border-radius: 1.875rem;

  @media ${lessThanContainer('large')} {
    padding-right: 2.6875rem;

    gap: 1.75rem 0;
  }

  @media ${lessThanContainer('medium')} {
    gap: 1.75rem 3rem;
  }

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
    gap: 1.25rem;

    padding: 1.875rem;
  }
`;
