import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент обертка списка.
 */
export const Wrap = styled.div`
  width: 59.375rem;

  padding: 2.5rem 5rem;

  border-radius: 1.875rem;

  background-color: ${({ theme }) => theme.color.primary.contrast};

  @media ${lessThanContainer('large')} {
    width: 56.25rem;

    padding: 0.625rem 3.125rem;
  }

  @media ${lessThanContainer('small')} {
    width: 100%;

    padding: 0;
  }
`;
