import { ComponentPropsWithoutRef, FC } from 'react';
import { LinkButton } from 'modules/common/ui';

import { Section } from './Faq.Section';
import { Container } from './Faq.Container';
import { Wrap } from './Faq.Wrap';
import {
  Accordion,
  Description as AccordionDescription,
  List as AccordionList,
  ListItem as AccordionListItem,
} from './Accordion';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Section>;

/**
 * Отображает блок "Часто задаваемые вопросы".
 */
export const Faq: FC<Props> = ({ ...props }) => (
  <Section title="Часто задаваемые вопросы" dark {...props}>
    <Container>
      <Wrap>
        <Accordion title="Что такое займы онлайн?" isOpened>
          <AccordionDescription>
            Онлайн-заём – это микрокредит на сумму до 100 000 ₽ на срок до 1
            года. Онлайн-займы оформляются по упрощённой схеме (онлайн, без
            посещения офиса). Не нужно тратить время на посещение банковского
            учреждения и предоставлять большой пакет документов – для оформления
            онлайн-займа достаточно действующего паспорта гражданина РФ.
            Выдавать займы имеют право только микрофинансовые организации,
            зарегистрированные в Государственном реестре МФО Центробанка РФ.
          </AccordionDescription>
        </Accordion>
        <Accordion title="Что нужно, чтобы получить заём?">
          <AccordionDescription>
            Для того, чтобы оформить заявку, Вам должно исполниться 18 лет, из
            документов понадобится действующий паспорт гражданина РФ.
          </AccordionDescription>
        </Accordion>
        <Accordion title="На какую сумму можно взять заём?">
          <AccordionDescription>
            От 1 000 до 20 000 рублей.
          </AccordionDescription>
        </Accordion>
        <Accordion title="На какой срок выдаются займы?">
          <AccordionDescription>До 15 календарных дней.</AccordionDescription>
        </Accordion>
        <Accordion title="Как погасить заём?">
          <AccordionDescription>
            С помощью формы оплаты в Личном кабинете, используя мобильное
            приложение своего банка, на веб-сайте своего банка, с помощью
            платёжного сервиса ЮMoney.
          </AccordionDescription>
        </Accordion>
        <Accordion title="Что делать, если просрочил платёж?">
          <AccordionDescription>
            Если вовремя не погасить заём, возникнет просроченная задолженность
            и начнёт начисляться неустойка. Также это грозит снижением
            кредитного рейтинга. Чтобы этого избежать, Вы можете подключить
            услугу «Снижение платежа», отсрочив дату внесения оплаты и снизив
            сумму ежемесячного платежа.
            <span>
              В рамках антикризисных мер поддержки предоставляем заёмщикам
              возможность воспользоваться{' '}
              <LinkButton
                href="/docs/26-o-kreditnykh-kanikulakh.pdf"
                target="_blank"
                variant="plain"
                color="info"
              >
                Кредитными каникулами
              </LinkButton>{' '}
              во исполнение закона №106-ФЗ. По вопросу предоставления
              специальных условий погашения займа необходимо обратиться в службу
              поддержки клиентов{' '}
              <LinkButton
                href="mailto:support@apollon-zaym.ru"
                target="_blank"
                variant="plain"
                color="info"
              >
                support@apollon-zaym.ru
              </LinkButton>{' '}
              или на горячую линию по номеру{' '}
              <LinkButton
                href="tel:+74996499059"
                target="_blank"
                variant="plain"
                color="info"
              >
                +7 (499) 649-90-59
              </LinkButton>
            </span>
          </AccordionDescription>
        </Accordion>
        <Accordion title="В каких случаях можно оформить реструктуризацию займа?">
          <AccordionDescription>
            Реструктуризация задолженности по договору потребительского займа
            возможна в таких случаях:
          </AccordionDescription>
          <AccordionList>
            <AccordionListItem>
              смерть получателя финансовой услуги;
            </AccordionListItem>
            <AccordionListItem>
              несчастный случай, повлекший причинение тяжкого вреда здоровью
              получателя финансовой услуги или его близких родственников;
            </AccordionListItem>
            <AccordionListItem>
              присвоение получателю финансовой услуги инвалидности I-II группы
              после заключения договора об оказании финансовой услуги;
            </AccordionListItem>
            <AccordionListItem>
              тяжелое заболевание получателя финансовой услуги, длящееся не
              менее 21 (двадцати одного) календарного дня со сроком реабилитации
              свыше 14 (четырнадцати) календарных дней;
            </AccordionListItem>
            <AccordionListItem>
              вынесение судом решения о признании получателя финансовой услуги
              недееспособным или ограниченным в дееспособности;
            </AccordionListItem>
            <AccordionListItem>
              единовременная утрата имущества на сумму свыше 500 000 (пятисот
              тысяч) рублей получателем финансовой услуги по договору
              потребительского займа;
            </AccordionListItem>
            <AccordionListItem>
              потеря работы или иного источника дохода получателем финансовой
              услуги в течение срока действия договора займа с последующей
              невозможностью трудоустройства в течение 3 (трёх) месяцев и более
              в случае, если получатель финансовой услуги имеет
              несовершеннолетних детей либо семья получателя финансовой услуги в
              соответствии с законодательством Российской Федерации относится к
              категории неполных;
            </AccordionListItem>
            <AccordionListItem>
              обретение получателем финансовой услуги статуса единственного
              кормильца в семье;
            </AccordionListItem>
            <AccordionListItem>
              призыв получателя финансовой услуги в Вооруженные силы Российской
              Федерации;
            </AccordionListItem>
            <AccordionListItem>
              вступление в законную силу приговора суда в отношении получателя
              финансовой услуги, устанавливающего наказание в виде лишения
              свободы;
            </AccordionListItem>
            <AccordionListItem>
              произошедшее не по воле получателя финансовой услуги существенное
              ухудшение финансового положения, не связанное с указанными выше
              случаями, однако способное существенно повлиять на размер дохода
              получателя финансовой услуги и (или) его способность исполнять
              обязательства по договору об оказании финансовой услуги.
            </AccordionListItem>
          </AccordionList>
        </Accordion>
        <Accordion title="Что для этого потребуется?">
          <AccordionDescription>
            Все указанные выше факты требуют подтверждения документами,
            выданными государственными органами или уполномоченными
            организациями.
          </AccordionDescription>
          <AccordionDescription>
            Их необходимо предоставить вместе с заявлением о реструктуризации.
          </AccordionDescription>
          <AccordionDescription>
            Организация, предоставившая заём, имеет право запросить недостающие
            документы в случае, если заявление о реструктуризации было
            направлено без указанных документов и решение о его рассмотрении
            заявления не было принято без представления документов.
          </AccordionDescription>
        </Accordion>
      </Wrap>
    </Container>
  </Section>
);
