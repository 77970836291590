import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка.
 */
export const Image = styled.img`
  width: 1.75rem;

  @media ${lessThanContainer('small')} {
    width: 1.25rem;
  }
`;
