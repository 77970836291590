import { ComponentPropsWithoutRef, FC } from 'react';

import { Section } from './Plus.Section';
import { Container } from './Plus.Container';

import { CardOne } from './CardOne';
import { CardTwo } from './CardTwo';
import { CardThree } from './CardThree';

import cosmonaut from './assets/cosmonaut.png';
import cosmonaut2x from './assets/cosmonaut_2x.png';
import place from './assets/place.png';
import place2x from './assets/place_2x.png';
import rocket from './assets/rocket.png';
import rocket2x from './assets/rocket_2x.png';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Section>;

/**
 * Отображает блок "Плюсы заема".
 */
export const Plus: FC<Props> = ({ ...props }) => (
  <Section {...props}>
    <Container>
      <CardOne
        title="Проще простого"
        description="поручители и залоговое имущество не нужны"
        image={cosmonaut}
        image2x={cosmonaut2x}
      />
      <CardTwo
        title="Получите заём не выходя из дома"
        description="мы всегда рядом: работаем 24/7 без праздников и выходных"
        image={place}
        image2x={place2x}
      />
      <CardThree
        title="С космической скоростью"
        description="просто отправьте запрос через онлайн-форму, чтобы получить деньги"
        image={rocket}
        image2x={rocket2x}
      />
    </Container>
  </Section>
);
