import styled from '@emotion/styled';

/**
 * Корневой элемент блока.
 */
export const Root = styled.section`
  color: ${({ theme }) => theme.color.text.main.main};
  background-color: ${({ theme }) => theme.color.primary.contrast};

  position: relative;
  overflow: hidden;
`;
