import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент заголовка содержимого лида.
 */
export const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 0;

  font-size: 3.5rem;

  @media ${lessThanContainer('small')} {
    font-size: 1.375rem;
  }
`;
