import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заголовок компонента.
 */
export const Title = styled.div`
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  letter-spacing: -1%;

  @media ${lessThanContainer('small')} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
