import styled from '@emotion/styled';

import { Container as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Отображает список карточек с плючами заема.
 */
export const Container = styled(Base)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0 11.875rem;

  @media ${lessThanContainer('large')} {
    gap: 0 5rem;
  }

  @media ${lessThanContainer('medium')} {
    grid-template-columns: 1fr;
  }
`;
