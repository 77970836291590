import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заглавие карточки.
 */
export const Title = styled.h3`
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25rem;

  @media ${lessThanContainer('small')} {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`;
