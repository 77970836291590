import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Section.Root';
import { Title } from './Section.Title';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок секции.
   */
  title?: string;
};

/**
 * Компонент отображающий секцию блока на главной странице.
 */
export const Section: FC<Props> = ({ title, children, ...props }) => (
  <Root {...props}>
    {title && <Title>{title}</Title>}
    {children}
  </Root>
);
