import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заглавие карточки.
 */
export const Title = styled.div`
  margin: 0;
  padding-top: 2.5rem;

  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;

  @media ${lessThanContainer('small')} {
    padding-top: 1.875rem;

    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`;
