import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Темный фон.
   */
  dark?: boolean;
};

/**
 * Корневой элемент блока.
 */
export const Root = styled.section<Props>`
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;

  color: ${({ theme }) => theme.color.text.main.main};
  background-color: ${(props) => {
    return props.dark ? '#F5F8FE' : props.theme.color.primary.contrast;
  }};

  @media ${lessThanContainer('small')} {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;

    background-color: ${({ theme }) => theme.color.primary.contrast};
  }
`;
