import { ComponentPropsWithoutRef, FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

import { Section } from './How.Section';
import { Container } from './How.Container';
import { Slide } from './How.Slide';
import { SlideContent } from './How.SlideContent';

import { Card } from './Card';

import notebook from './assets/notebook.png';
import notebook2x from './assets/notebook_2x.png';
import clock from './assets/clock.png';
import clock2x from './assets/clock_2x.png';
import card from './assets/card.png';
import card2x from './assets/card_2x.png';

SwiperCore.use([Pagination, Navigation]);

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Section>;

/**
 * Отображает блок "Как взять заём?".
 */
export const How: FC<Props> = ({ ...props }) => (
  <Section title="Как взять заём?" {...props}>
    <Container>
      <Swiper
        className="swiper-home"
        style={{ width: '100%', maxWidth: '53.375rem' }}
        slidesPerView="auto"
        navigation
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <Slide>
            <SlideContent>
              <Card
                title="заполните анкету и подтвердите личность"
                description="прикрепите фото с паспортом в развороте"
                image={notebook}
                image2x={notebook2x}
              />
            </SlideContent>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <SlideContent>
              <Card
                title="дождитесь одобрения"
                description="ответ будет автоматически отправлен вам с помощью SMS"
                image={clock}
                image2x={clock2x}
              />
            </SlideContent>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <SlideContent>
              <Card
                title="получите деньги"
                description="вот и всё: деньги у вас на карте 😉"
                image={card}
                image2x={card2x}
              />
            </SlideContent>
          </Slide>
        </SwiperSlide>
      </Swiper>
    </Container>
  </Section>
);
