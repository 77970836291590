import styled from '@emotion/styled';

import { Container, lessThanContainer } from 'modules/common/ui';

/**
 * Элемент содержимого лида главной страницы.
 */
export const Content = styled(Container)`
  position: relative;
  z-index: 1;

  padding-top: ${({ theme }) => theme.header.size.large}rem;
  padding-bottom: ${({ theme }) => theme.header.size.large}rem;

  margin-bottom: auto;
  margin-top: auto;

  max-width: ${({ theme }) => theme.container.size.small}px;

  @media ${lessThanContainer('medium')} {
    padding-top: ${({ theme }) => theme.header.size.medium}rem;
    padding-bottom: ${({ theme }) => theme.header.size.medium}rem;
  }
`;
