import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Иконка карточки.
 */
export const Image = styled.img`
  grid-column: 2;
  grid-row: 1 / span 2;

  justify-self: end;

  @media ${lessThanContainer('large')} {
    position: relative;
    top: -2rem;

    width: 3.4375rem;

    align-self: start;
  }

  @media ${lessThanContainer('medium')} {
    top: auto;
    width: 5.125rem;
  }

  @media ${lessThanContainer('small')} {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;

    width: 2.75rem;
  }
`;
