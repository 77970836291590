import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает список карточек с вариантами заема.
 */
export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${lessThanContainer('medium')} {
    flex-direction: column;
  }
`;
