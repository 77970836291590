import { FC, ComponentPropsWithoutRef } from 'react';

import { Calculator as Base, CalculatorV2 as BaseV2 } from 'modules/calculator';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает калькулятор, который должен быть расположен в публичной части
 * сайта. Такой калькулятор, при нажатии кнопки "Получить", выполняет
 * перенаправление пользователя на служебную страницу создания заявки на заём,
 * подставляя в неё выбранные параметры.
 */
export const Calculator: FC<Props> = ({ ...props }) => {
  return (
    <>
      <BaseV2 {...props} />
    </>
  );
};
