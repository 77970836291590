import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Флаг указывающий на состояние открытия заголовка.
   */
  isOpened: boolean;
};

/**
 * Корневой элемент блока.
 */
export const Root = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr 1rem;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  padding-bottom: ${(props) => {
    return props.isOpened ? '1.625rem' : '0';
  }};

  transition: ${transition('padding-bottom')};

  @media ${lessThanContainer('small')} {
    padding-bottom: ${(props) => {
      return props.isOpened ? '0.75rem' : '0';
    }};
  }
`;
