import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает внизу блока.
 */
export const Description = styled.p`
  margin: 6.25rem 0 0;

  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;

  text-align: center;

  color: #5e646e;

  @media ${lessThanContainer('medium')} {
    margin-top: 2.5rem;
  }

  @media ${lessThanContainer('small')} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;
