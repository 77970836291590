import { ComponentPropsWithoutRef, FC } from 'react';

import { Page } from 'modules/common/routing';

import { Root } from './Help.Root';
import { Content } from './Help.Content';
import { Title } from './Help.Title';
import { Description } from './Help.Description';
import { LinkButton } from './Help.LinkButton';
import { Container } from './Help.Container';
import { Image } from './Help.Image';
import { WrapLinkButton } from './Help.WrapLinkButton';

import planet from './assets/planet.png';
import planet2x from './assets/planet_2x.png';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает блок "Всегда готовы помочь".
 */
export const Help: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Container>
      <Content>
        <Title>Всегда готовы помочь</Title>
        <Description>
          <span>
            Аполлон Займ поможет решить Ваш финансовый вопрос — быстро и без
            лишней бюрократии.
          </span>{' '}
          <span>Отправьте онлайн-заявку — и получите деньги на карту.</span>
        </Description>
        <WrapLinkButton>
          <LinkButton href={Page.ABOUT} variant="outlined" width="auto">
            О компании
          </LinkButton>
        </WrapLinkButton>
      </Content>
      <Image src={planet} srcSet={`${planet2x} 2x`} alt="planet" />
    </Container>
  </Root>
);
