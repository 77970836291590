import styled from '@emotion/styled';

/**
 * Корневой элемент пейзажа главной страницы.
 */
export const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
  background: url('/landscape.jpg');
  background-position: center;
  background-size: cover;
`;
