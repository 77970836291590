import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент содержимого дополнительного контента лида.
 */
export const Aside = styled.aside`
  margin-top: 3rem;

  // Вычисляется из занимаемой ширины текстом из основного контейнера
  // минус радиус скругления углов калькулятора.
  max-width: 38.25rem;

  margin-right: auto;
  margin-left: auto;

  @media ${lessThanContainer('small')} {
    margin-top: 1rem;

    // Вычисляется из занимаемой ширины текстом из основного контейнера
    // минус радиус скругления углов калькулятора.
    max-width: 21.375rem;
  }
`;
