import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент оборачивающий содержимое в тег `li`.
 */
export const ListItem = styled.li`
  font-style: normal;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: -1%;

  @media ${lessThanContainer('small')} {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
`;
