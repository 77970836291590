import styled from '@emotion/styled';

/**
 * Элемент выделенного текста содержимого лида.
 */
export const Accent = styled.span`
  white-space: nowrap;

  color: ${({ theme }) => theme.color.text.main.accent.main};
`;
