import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает компонент для контента.
 */
export const Content = styled.div`
  position: relative;
  z-index: 1;

  max-width: 41.5rem;

  @media ${lessThanContainer('medium')} {
    max-width: auto;
  }
`;
