import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Title as Base } from '../Title';

/**
 * Компонент заголовка.
 */
export const Title = styled(Base)`
  text-align: center;

  padding: 0 1rem 6.25rem;

  @media ${lessThanContainer('small')} {
    padding: 0 1rem 3.125rem;
  }
`;
