import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Social as Base } from './Social';

/**
 * Компонент социальных сетей.
 */
export const Social = styled(Base)`
  grid-column: 1;

  margin-top: 2.8125rem;

  @media ${lessThanContainer('medium')} {
    justify-content: center;
  }

  @media ${lessThanContainer('small')} {
    margin-top: 1.875rem;
  }
`;
