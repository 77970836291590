import styled from '@emotion/styled';

/**
 * Элемент содержимого лида.
 */
export const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 600;
  letter-spacing: 0.065em;
  text-align: center;
  color: ${({ theme }) => theme.color.text.inverse.main};
`;
