import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент обертка картинки.
 */
export const WrapImage = styled.div`
  grid-column: 2;
  grid-row: 1 / span 3;

  border-radius: 1.875rem;

  width: 15rem;

  overflow: hidden;

  @media ${lessThanContainer('medium')} {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;

    border-radius: 100%;

    width: 8rem;
    height: 8rem;
  }
  @media ${lessThanContainer('small')} {
    width: 6rem;
    height: 6rem;
  }
`;
