import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  padding: 2.5rem 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.13);

  user-select: none;

  :last-of-type {
    border-bottom: 0;
  }

  @media ${lessThanContainer('small')} {
    padding: 1.875rem 0;
  }
`;
