import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Флаг указывающий на состояние открытия.
   */
  isOpened: boolean;
};

/**
 * Иконка компонента.
 */
export const Icon = styled.div<Props>`
  > svg {
    transform-origin: 50% 50%;
    transition: ${transition('transform')};
    transform: ${(props) =>
      props.isOpened ? 'rotate(-90deg)' : 'rotate(90deg)'};
  }
`;
