import { FC } from 'react';

import { Nowrap } from 'modules/common/ui';
import { Page } from 'modules/common/routing';

import { Landscape } from '../Landscape';
import { Root } from './LeadV2.Root';
import { Heading } from './LeadV2.Heading';
import { Description } from './LeadV2.Description';
import { WrapLinkButton } from './LeadV2.WrapLinkButton';
import { LinkButton } from './LeadV2.LinkButton';
import { Container } from './LeadV2.Container';

export const LeadV2: FC = () => {
  return (
    <Root>
      <Container>
        <Heading>
          <Nowrap>Онлайн-заём</Nowrap>
        </Heading>
        <Description>У нас всё по-честному</Description>
        {/* {children} */}
        <WrapLinkButton>
          <LinkButton href={Page.LOGIN}>Оплатить</LinkButton>
        </WrapLinkButton>
      </Container>
      <Landscape />
    </Root>
  );
};
