import styled from '@emotion/styled';

export const Root = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: hidden;
`;
