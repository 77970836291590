import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент текста содержимого лида.
 */
export const Text = styled.p`
  margin-bottom: 0;
  margin-top: 0.75rem;

  font-size: 1.5rem;

  @media ${lessThanContainer('small')} {
    margin-top: 0.25rem;
    font-size: 1rem;
  }
`;
