import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент обертка карточки займа.
 */
export const Wrap = styled.div`
  position: relative;

  width: 100%;
  height: 23.75rem;

  @media ${lessThanContainer('medium')} {
    height: 100%;
  }
`;
