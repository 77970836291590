import styled from '@emotion/styled';

/**
 * Компонент отображающий контент слайда.
 */
export const SlideContent = styled.div`
  display: flex;

  justify-content: center;
`;
