import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент оборачивающий содержимое в тег `ul`.
 */
export const List = styled.ul`
  margin: 20px 0;
  padding: 0 0 0 20px;

  @media ${lessThanContainer('small')} {
    margin: 10px 0;
    padding: 0 0 0 10px;
  }
`;
