import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент блока.
 */
export const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.75rem;

  @media ${lessThanContainer('small')} {
    gap: 2.5rem;
  }
`;
